import * as d3 from 'd3';
import moment from 'moment-timezone';
import { formatterDate, formatterTime, formatterShortWeekDay, getTimezoneOffset, setUpBins } from './timeFunctions';

export type NetEnergyChartOptions = {
  [key:string]: any;
  data: {
    start: string;
    interval_seconds: number;
    measure: string;
    series: {
      [key:string]: {
        [key:string]: number[]
      }
    }
  }[]
};

type GridItem = {
  date: Date;
  [key:string]: number | Date | null;
};

const renderNetEnergyChart = (options: NetEnergyChartOptions) =>{
    if(!options.data){
      return;
    }

    const querySegmentIds = Array.from(
      options.data.reduce(
        (acc:Set<string>, {series}:any) => {
          for(const querySegmentId of Object.keys(series)|| []){
            acc.add(querySegmentId);
          }
          return acc;
        },
        new Set()
      )
    );

    const dataMap: Map<number, { [key:string]: number | null }> = new Map();
    const intervalSecondsMap : Map<number, number> = new Map();
    // parse all results and create a flat map of dates and values
    for(const { start, interval_seconds, series } of options.data){
      const seriesLength = series[Object.keys(series)[0]][options.measure].length;
      const startDate = new Date(start);
      for(let i=0; i<seriesLength; i+=1){
        let d = new Date(startDate.getTime() + interval_seconds * i * 1000);
        let item = dataMap.get(d.getTime());
        intervalSecondsMap.set(d.getTime(), interval_seconds);
        if(!item){
          item = {}; 
          dataMap.set(d.getTime(), item);
        }
        for(const [querySegmentId, measures] of Object.entries(series)){
          item[querySegmentId] = (measures[options.measure] || [])[i] ?? null;
        }
      }
    }

    const dateDomain = [
      ...Array.from(dataMap.keys()).map(time => new Date(time).toISOString())
    ];

    const grid : GridItem[]= [];
    for(const [i, [time, values]] of Array.from(dataMap.entries()).entries()){
      const item : GridItem = grid[i] = { date: new Date(time) };
      for(const [querySegmentId, value] of Object.entries(values)){
        item[querySegmentId] = value;
      }
    }

    const xScale = d3.scaleBand()
        .domain(dateDomain)
        .range([0, options.chartConstants.width])
        .paddingInner(0.08);
    
    const consumptionValues = Array.from(dataMap.values())
      .map(item => item.consumption)
      .filter(v => !isNaN(v as number));
    const consumptionMax = Math.max(...consumptionValues as number[]);
    const productionValues = Array.from(dataMap.values())
      .map(item => item.production)
      .filter(v => !isNaN(v as number));
    const productionMax = querySegmentIds.includes('production') ? Math.abs(Math.min(...productionValues as number[])): 0;
    const domainMax = Math.max(consumptionMax, productionMax);
    console.log(`Y Domain. consumption max ${consumptionMax}, production max ${productionMax}, will use domain max ${domainMax}`);
    // map the domain of the measurements onto the range of pixels available.
    const yScale = d3.scaleLinear()
    .domain([(-1 * domainMax), domainMax])
    .range([ options.chartConstants.height, 0 ]);

  const color = d3.scaleOrdinal()
    .domain(['consumption', 'production', 'grid_net'])
    .range(['#ff8726', '#6dd152','#0046c0'])

    const mouseOutBarChart = (event: any)=>{
      d3.select('.chartToolTipNetEnergy').style('display', 'none');
  };
  const mouseOverBarChart = (event: any)=>{
      const series = event.target.className.baseVal.match(/series-(\w*)/)[1];
      if(series){
        d3.select('.chartToolTipNetEnergy').style('display', 'block');
      }
      const data = event.target.__data__;
      // console.debug(`Mouse over series ${options.querySegmentLookup[series]} value : ${data[series]}`);
      const ttDate = `${formatterShortWeekDay.format(moment(data?.date).tz(options.location.timezone).toDate())} ${formatterDate.format(moment(data?.date).tz(options.location.timezone).toDate())}`;
      const ttTime = moment(data?.date).tz(options.location.timezone).format('h:mm A');
      const seriesColor : any = color(series);
      const seriesConsumption =  Math.round((data.val / 1000) * 100 )/ 100;

      const rightEdgeFlag = (event.pageX > (window.innerWidth - 140)) ? -120 :0;
      const bottomEdgeFlag = (event.pageY > (window.innerHeight - 140)) ? -140 :0;

      const tooltip = d3.select('.chartToolTipNetEnergy')
          .style('top', `${event.pageY + bottomEdgeFlag - 100}px`)
          .style('left', `${event.pageX + rightEdgeFlag}px`);
      tooltip.select('.ttDate').text(ttDate);
      tooltip.select('.ttTime').text(`${ttTime}`);
      tooltip.select('.chartTTSeries')
          .text(options.querySegmentLookup[series])
          .style('background', seriesColor);
          tooltip.select('.ttSeriesValue').text(`${seriesConsumption} `);
          tooltip.select('.ttMeasure').text(`${options.measure === 'p' ? 'watts' : 'kWh' }`);
      tooltip.select('.chartToolTipNetEnergy').style('display', 'block');
    };



  const svg = d3.select("#historicalChart").select('svg');
  const series = [ 'consumption', 'grid_net' ];
  if(querySegmentIds.includes('production')){
    series.unshift('production');
  }
  const groups = svg.append("g")
    .attr('class', 'series-bars')
    .selectAll("g")
    .data(grid)
    .enter().append("g")
    .selectAll('rect')
    .data((d: any)=>{
      return series.map((s: any)=>{
        const obj = {date: d.date, key: s, val: d[s]};
        return obj;
      });
    })
    .enter().append("rect")
      .attr("class", (d: any)=>{return `series-${d.key}`;})
      .style("fill", (d: any)=>{ 
          const fillColor: any = color(d.key);
          return fillColor;
      })
      .attr('opacity','90%')
      .attr("x", (d: any)=>{
        return xScale(d.date.toISOString()) || 0;})
      .attr("y", (d: any)=>{
        return yScale(Math.max(0, d.val));
      })
      .attr("height", (d: any)=>{
        return Math.abs(yScale(d.val) - yScale(0));
      })
      .attr("width", xScale.bandwidth())
      .on('mouseover', mouseOverBarChart)
      .on('mouseout', mouseOutBarChart);

    // now add axis ticks to overlay data
    const xAxisText = dateDomain.map((sampleDateString)=>{
      const d = new Date(sampleDateString);
      let rtn ='';
      switch(options.dateRange.range){
          case '3hr':
          case 'day':
              rtn = `${moment(d).tz(options.location.timezone).hours()}:${('0' + d.getMinutes()).slice(-2)}`;
              break;
          case 'week':
              rtn = moment(d).tz(options.location.timezone).toDate().toLocaleDateString();
              break;
          default:
              const intervalSeconds = intervalSecondsMap.get(d.getTime())!;
              const endSample = new Date(sampleDateString);
              endSample.setSeconds(endSample.getSeconds() + intervalSeconds);
              rtn = `${moment(d).tz(options.location.timezone).toDate().toLocaleDateString()} - ${moment(endSample).tz(options.location.timezone).toDate().toLocaleDateString()}`;
  }
      return rtn;
  });
  const xAxis = d3.axisBottom(xScale)
      .tickFormat((d,i)=>xAxisText[i]);
  svg.append("g")
    .attr("transform", `translate(0, ${options.chartConstants.height})`)
    .call(xAxis)
    .call((g : any) => g.selectAll("line")
      .attr("stroke", "#666")
      .attr("stroke-opacity", 0.4));
  const yAxis = d3.axisLeft(yScale)
    .tickSizeInner(options.chartConstants.idth);
  svg.append("g")
    .attr('class', 'y-axis')
    .attr("transform", `translate(${options.chartConstants.width}, 0)`)
    .call(yAxis)
    .call((g : any) => g.selectAll("line")
      .attr("stroke", "#666")
      .attr("stroke-opacity", 0.4))
    .call( (g: any) => g.selectAll(".tick text")
    .attr("class", "yAxisText")
    .attr('transform','translate(48, 12)'));
  
    d3.select('.y-axis .tick').remove();



  };
export {
  renderNetEnergyChart
};