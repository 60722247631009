import { useEffect } from "react";

const Live = ({liveData, querySegmentLookup}
  :{liveData:any, querySegmentLookup:any}) => {
  
  const segmentSorter = (a: string,b: string)=>{
    if (querySegmentLookup[a]?.toLowerCase() < querySegmentLookup[b]?.toLowerCase()) {
      return -1;
    }
    if ( querySegmentLookup[a]?.toLowerCase() >  querySegmentLookup[b]?.toLowerCase()) {
      return 1;
    }
    return 0;
  };
  const formatterNumber = new Intl.NumberFormat(undefined);
  useEffect(() => {
  }, [liveData, querySegmentLookup]);

  return (
    <div className="live-consumption-widget-container">
    <div className='live-consumption-segments'>{liveData?.data &&
      Object.keys(liveData.data).sort(segmentSorter).map(key=>{
        if(['grid_net', 'consumption', 'production'].includes(key)){
          return;
        }
        const val = liveData.data[key]['p'];
        const width =  (val<0) ? Math.log2(Math.abs(val)) * 70: Math.max(Math.log2(val) * 70, 18);
      return (
        <div key={key} className='live-consumption-segment'>
          <h4 className='live-consumption-label'>{querySegmentLookup[key]}</h4>
          <div className={'live-consumption-bar ' + (val< 0 ? 'negative-consumption' : '')} style={{width: width}}></div>
          <div className='live-consumption-value'>{formatterNumber.format(Math.round(val))}W</div>
        </div>
        )
      })
    }</div>
    </div>);
};

export default Live;