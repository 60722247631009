import * as d3 from 'd3';
import moment from 'moment-timezone';
import { formatterDate, formatterTime, formatterShortWeekDay, getTimezoneOffset, setUpBins,  } from './timeFunctions';

export type NetPowerChartOptions = {
  [key:string]: any;
  data: {
    start: string;
    interval_seconds: number;
    measure: string;
    series: {
      [key:string]: {
        [key:string]: number[]
      }
    }
  }[]
};
export class NetPowerChart {
  name = 'NetPowerChart';
  formatterNumber = new Intl.NumberFormat(undefined);
  querySegmentIds : string[];
  sumstat : Map<string, number[]>;
  dates : Date[] = [];
  x : any;
  y : any;
  solarNetSeries = ['consumption', 'production', 'grid_net'];
  solarColors = ['#ff8726', '#6dd152','#0046c0', '#6b7280', '#525659','#6fd7fc','#ffbbd4'];
  color : any;
  options : any;
  graphCursor : any;
  seriesDisplay : any;
  svg : any;
  inactiveSeries :any;

  prepData () {
    for(const querySegmentId of this.querySegmentIds){
      let collector :any = [];
      for(const { series } of this.options.data){
        const values = series[querySegmentId];
        if(values){
          const measureValues = values[this.options.measure];
          if(Array.isArray(measureValues)){
            collector = collector.concat(measureValues);
          }
        }
      }
      this.sumstat.set(querySegmentId, collector);
    }

    for(const { start, interval_seconds, series } of this.options.data){
      const seriesLength = series[Object.keys(series)[0]][this.options.measure].length;
      const startDate = new Date(start);
      for(let i=0; i<seriesLength; i+=1){
        this.dates.push(new Date(startDate.getTime() + interval_seconds * i * 1000));
      }
    }
    this.dates.sort((a, b) => a.getTime() - b.getTime());
    this.querySegmentIds.forEach( (s) => {
      if(!this.solarNetSeries.includes(s)){
        this.inactiveSeries.add(s);
      }
    });

  }
  prepChartDeps () {
    const startDate = this.dates[0];
    const endDate = this.dates[this.dates.length-1];
    this.x = d3.scaleUtc()
      .domain([startDate, endDate])
      .range([ 0, this.options.chartConstants.width ]);
      const consumptionMax = Math.max(...this.sumstat.get('consumption')!);
      const productionMax = this.sumstat.has('production') ? Math.abs(Math.min(...this.sumstat.get('production')!)) : 0;
      const domainMax = Math.max(consumptionMax, productionMax) * 1.2;
    this.y = d3.scaleLinear()
      .domain([-1 * domainMax, domainMax])
      .range([ this.options.chartConstants.height, 0 ]);
  
    this.color = d3.scaleOrdinal()
      .domain(this.solarNetSeries)
      .range(this.solarColors);

        // alter labels for legend
  const alteredQSlookup :any = {};
  this.seriesDisplay = {};
  for(const key of Object.keys(this.options.querySegmentLookup)){
    switch (key){
      case 'consumption':
        alteredQSlookup[key] = 'Consumption';
        this.seriesDisplay[key] = true;
        break;
      case 'grid_net':
        alteredQSlookup[key] = 'Net';
        this.seriesDisplay[key] = true;
        break;
      case 'production':
        alteredQSlookup[key] = 'Production';
        this.seriesDisplay[key] = true;
        break;
      default:
        try {
          this.seriesDisplay[key] = false;
          // increment each main index (so that it is 1 based VS 0 based)
          const rx = /(.*)__(\d*)/;
          const [, hubId, registerIndex] = rx.exec(key) || ['','',''];
          const index = parseInt(registerIndex, 10) + 1;
          alteredQSlookup[key] = `${this.options.querySegmentLookup[key]}:${hubId}:${index}`;
        }
        catch (err) {
          // if this didn't work something is wrong
          alteredQSlookup[key] = `${this.options.querySegmentLookup[key]}:${key}`;
        }
      break;
      }
  }

  this.options.makeGraphLegend(alteredQSlookup, this.color);

  }
  updateChart (){

    let overlap = false;
    //TODO might consider checking that the series are identical
    if(!this.querySegmentIds.includes('production')){
      overlap = true;
      d3.select('.tooltip-solar').style('width', '240px');
      d3.select('.label-production').style('display', 'none');
      d3.select('.measure-production').style('display', 'none');
    } else {
      d3.select('.tooltip-solar').style('width', '360px');
      d3.select('.label-production').style('display', 'block');
      d3.select('.measure-production').style('display', 'inline');
    }
  
  
    const lineGenerator = d3.line()
      .x((d: any, i: number )=>{
        const sampleTime = this.dates[i];
        return this.x(sampleTime); })
      .y((d: any)=>{ 
        //TODO 🤔 not sure this is the best way to deal with null values...
        return this.y(d ?? 0); });
    
    this.svg = d3.select("#historicalChart").select('svg');
    this.svg
      .selectAll('series')
      .data(this.sumstat)
      .enter()
      .append('path')
      .attr('class' , (d: any)=>{return `chart-series series-${d[0]}`;})
      .style('fill', 'none')
      .style('stroke', (d:any)=>{return this.color(d[0]) as string;})
      .style('stroke-width', 2)
      .style("stroke-opacity", 0.9)
      .style('display', (d:any) => {
        const seriesId = d[0];
        return this.inactiveSeries.has(seriesId) ? 'none' : '';
      })
      .style('stroke-dasharray',(d:any)=>{
          return (overlap && d[0] === 'grid_net') ? '5,5' : null;
      })
      .attr("d", (d: any) => {
          return lineGenerator(d[1])
      });
  
  }
  updateAxes() {
  // now add axis ticks to overlay data
  let selectedTicks :any = [];
  let xTicks:any;
  const timezoneOffset = moment.tz(this.options.location.timezone).utcOffset() / 60;
  switch (this.options.dateRange.range){
    case '3hr':
      selectedTicks = d3.utcMinute.every(15);
      xTicks = d3.axisBottom(this.x).ticks(selectedTicks);
      break;
    case 'day':
      selectedTicks = d3.utcHour.every(3);
      xTicks = d3.axisBottom(this.x).ticks(selectedTicks);
      break;
    case 'week':
      selectedTicks = d3.utcHour.range(this.options.dateRange.start,this.options.dateRange.end).filter((d) => {
        const rtn = d.getUTCHours()===(-1 * timezoneOffset);
        return rtn;
      });
      xTicks = d3.axisBottom(this.x).tickValues(selectedTicks);
      break;
    case 'month':
      selectedTicks = d3.utcHour.range(this.options.dateRange.start,this.options.dateRange.end).filter((d) => {
        const rtn = (d.getUTCHours()===(-1 * timezoneOffset)) && d.getUTCDay()===0;
        return rtn;
      });
      xTicks = d3.axisBottom(this.x).tickValues(selectedTicks);
      break;
    case 'year':
      selectedTicks = d3.utcMonth.every(1);
      xTicks = d3.axisBottom(this.x).ticks(selectedTicks);
      break;
    };

  this.svg.append("g")
    .attr('class', 'x-axis')
    .attr("transform", `translate(0, ${this.options.chartConstants.height})`)
    .call(xTicks
      .tickFormat((d:any)=>{
        let formatPattern = 'hh:mma';
        switch (this.options.dateRange.range){
          case 'week':
          case 'month':
          case 'year':
            formatPattern = 'MMMM Do';
        }
        const label = moment(d).tz(this.options.location.timezone).format(formatPattern);
        return label;
      })
  )
    .call((g : any) => g.selectAll("line")
      .attr("stroke", "#666")
      .attr("stroke-opacity", 0.4));


  const yAxis = d3.axisLeft(this.y)
    .tickSizeInner(this.options.chartConstants.width);
  this.svg.append("g")
    .attr("transform", `translate(${this.options.chartConstants.width}, 0)`)
    .call(yAxis)
    .call((g : any) => g.selectAll("line")
      .attr("stroke", "#666")
      .attr("stroke-opacity", 0.4))
    .call( (g: any) => g.selectAll(".tick text")
    .attr("class", "yAxisText")
    .attr('transform','translate(36, 12)'));

    this.svg
    .append('rect')
    .style("fill", "none")
    .style("pointer-events", "all")
    .attr('width', this.options.chartConstants.width)
    .attr('height', this.options.chartConstants.height)
    .on('mousemove', this.mousemove.bind(this));
    
  this.graphCursor = this.svg
      .append('g')
      .append('line')
        .attr("stroke", "#666")
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', this.options.chartConstants.height)
        .style("opacity", 0.3);

    setTimeout(this.updateLegend.bind(this), 300);
  }
  constructor (options: NetPowerChartOptions) {
    this.options = options;
    this.querySegmentIds = [];
    this.sumstat = new Map();
    this.inactiveSeries = new Set();

    if(!options.data?.length){
      return;
    }
    this.querySegmentIds = Array.from(
      options.data.reduce(
        (acc:Set<string>, {series}:any) => {
          for(const querySegmentId of Object.keys(series)){
            acc.add(querySegmentId);
          }
          return acc;
        },
        new Set()
      )
    );
    this.prepData();
    this.prepChartDeps();
    this.updateChart();
    this.updateAxes();
//  *********************************************
}

  mousemove(event: any) {
    d3.select('.tooltip-solar').style('display', 'block');
    const dateAtCursor = this.x.invert(d3.pointer(event)[0]);
    const focusedDateIndex = d3.bisectRight(this.dates.map(d => d.getTime()), dateAtCursor.getTime());
    const dateAtFocus = this.dates[focusedDateIndex];
    const cursorDate = dateAtFocus.getDate() + ' ' + dateAtFocus.toLocaleString('default', { month: 'short' }) + ' ' 
      + dateAtFocus.getFullYear() + ' ' + String(dateAtFocus.getHours()).padStart(2,"0") + ':' + String(dateAtFocus.getMinutes()).padStart(2, '0');
    const ttDate = `${formatterShortWeekDay.format(dateAtFocus)} ${formatterDate.format(dateAtFocus)} `;
    this.graphCursor
    .attr("x1", this.x(dateAtFocus))
    .attr("x2", this.x(dateAtFocus));
  
    const ttmin = Math.max(event.pageX - 180, 0);
    const ttwidth = (this.querySegmentIds.length === 2)? 240 : 360;
    let toolTipLeft = Math.min( ttmin , (window.innerWidth - ttwidth));
    const tooltip = d3.select('.tooltip-solar');
    tooltip.style('top', `${event.pageY}px`)
           .style('left', `${toolTipLeft}px`);
    tooltip.select('.ttDate').text(ttDate);
    tooltip.select('.chartDate').text(cursorDate);
    tooltip.select('.ttTime').text(`${ moment(dateAtFocus).tz(this.options.location.timezone).format('h:mm A')}`);
  
    for(const [i, [querySegmentId, values]] of Array.from(this.sumstat.entries()).entries()){
      const stackColor : any = this.color(querySegmentId);
      const cy = this.y(values[focusedDateIndex]);
      const measure = values[focusedDateIndex] || 0;
      const formattedMeasure = this.formatterNumber.format(Math.round(measure * 100)/100);
      tooltip.select(`.measure-${querySegmentId}`).text(`${formattedMeasure}W`);
  
      d3.select(`#cursor-${querySegmentId}`)
        .attr("fill", stackColor)
        .attr('cx', this.x(dateAtFocus))
        .attr('cy', cy);
    }
    for(const series in this.options.data[0].series){
      try{
        const value = this.options.data[0].series[series]['p'][focusedDateIndex] || 0;
        const formatted =  this.formatterNumber.format(Math.round(value * 100)/100);
        d3.select(`span.series-${series}`)?.text(formatted);
      } finally {
        //if it couldn't be formatted don't show it
      }
    }
  }
  toggleSeries (seriesId: string) {
    if(seriesId === 'all') {
      this.inactiveSeries.clear();
      document.querySelectorAll(`path.chart-series`).forEach((path)=>{
        d3.select(path).style('display', '');
      });
    } else if (seriesId === 'none') {
      this.querySegmentIds.forEach((qsid: any)=>{
        this.inactiveSeries.add(qsid);
      });
      document.querySelectorAll(`path.chart-series`).forEach((path)=>{
        d3.select(path).style('display', 'none');
      });
    } else {
      if(this.inactiveSeries.has(seriesId)){
        this.inactiveSeries.delete(seriesId);
      } else {
        this.inactiveSeries.add(seriesId);
      }
      const seriesPath = d3.select(`path.series-${seriesId}`);
      const toggle = seriesPath?.style('display');
      seriesPath.style('display', toggle === 'none' ? '': 'none');
    }
  }
  updateLegend () {
    // un check mains in legend
    for(const key of Object.keys(this.options.querySegmentLookup)){
      try {
        const li = document.querySelector(`span.series-${key}`)?.parentElement;
        if(this.inactiveSeries.has(key)){
          li?.classList.add('qs-off');
        } else {
          li?.classList.remove('qs-off');
        }
      } 
      finally{}
    }
  }
  resize (newSize : any) {
    this.options.chartConstants.width = newSize.width;
    this.options.chartConstants.height = newSize.height;
    this.prepChartDeps();
    this.updateChart();
    this.updateAxes();
};
}