const _WebSocketManagerStates = {
  UNCONNECTED: 'UNCONNECTED',
  CONNECTING: 'CONNECTING',
  CONNECTED: 'CONNECTED',
  SUBSCRIBING: 'SUBSCRIBING',
  UNSUBSCRIBING: 'UNSUBSCRIBING',
  DISCONNECTING: 'DISCONNECTING'
};

class LiveDataServiceClient {
  webSocketServer:WebSocket|undefined = undefined;
  publicServiceClient:any;
  querySegments: any;
  lastLiveDataReceived : Date|undefined;
  minuteDataTimeoutId : any
  liveDataIntervalId : any
  liveDataMonitorIntervalPeriod : number = parseInt(`${process.env.REACT_APP_LIVE_DATA_MONITOR_INTERVAL_PERIOD || (10 *1000)}` , 10);
  fallbackPollingInterval : number = parseInt(`${process.env.REACT_APP_FALLBACK_POLLING_INTERVAL || (60 * 1000)}` , 10);
  maxWebsocketRetry : number = parseInt(`${process.env.REACT_APP_MAX_WEBSOCKET_RETRY || 10}` , 10);
  liveDataMaxLag : number = 3 * 1000;
  liveDataReceivedCount : number = 0;
  fallbackActive : boolean = false;
  _dispatch:undefined|Function = undefined;
  websocketRetryCount : number = 0;
  websocketRetryMax : number = 3;
  _locationId:string|undefined = undefined;
  _currentWebSocketStatus =   _WebSocketManagerStates.UNCONNECTED;
  private static instance: LiveDataServiceClient;
  private static _webSocket: WebSocket|undefined;
  private _token: string|undefined;
  private _heartbeatInterval : any;
  private _heartbeatActive : boolean = false;
  private _subscriptionHash: string|undefined = undefined;

subscriptionMessageGenerator = (id:string, payloadType = 'location', segment_requests:any  = {"consumption":["p"]}) => {
  return {
    type: 'subscribe',
    payload: {
      type: payloadType,
      id,
      segment_requests
    }
  };
};

authenticationMessageGenerator = (token:string) => {
  return {
    type: 'authorize',
    payload: {
      access_token: token
    }
  };
}

connect = async (onOpen : any = undefined) => {
  this._currentWebSocketStatus =   _WebSocketManagerStates.SUBSCRIBING;
  const authMessage = this.authenticationMessageGenerator(this._token || ''); // + 'nuke-auth'; // this will force an error on the websocket
  LiveDataServiceClient._webSocket = await new WebSocket(`${process.env.REACT_APP_LDS_API_ROOT}`);
  LiveDataServiceClient._webSocket.addEventListener('error', (e) => {
      console.error('websocket error:', e);
      this.fallback();
    });
  LiveDataServiceClient._webSocket.addEventListener('open', () => {
    LiveDataServiceClient._webSocket?.send(JSON.stringify(authMessage));
    this._currentWebSocketStatus =   _WebSocketManagerStates.CONNECTED;
    this.liveDataIntervalId = setInterval(this.monitorLiveDataFeed, this.liveDataMonitorIntervalPeriod);
    if (typeof onOpen === 'function'){
      onOpen();
    }
  });
  LiveDataServiceClient._webSocket.addEventListener("message", (event) => {
      this.liveDataReceivedCount++;

      document.querySelector('body')?.classList.add('live-data');
      document.querySelector('body')?.classList.remove('live-data-loading');
      this.lastLiveDataReceived = new Date();
      this.messageHandler(event.data);
    });
  LiveDataServiceClient._webSocket.addEventListener('close', () => {
    this._currentWebSocketStatus =   _WebSocketManagerStates.DISCONNECTING;
    console.debug('The websocket was closed.')
    this.fallback();
    this.websocketRetry();
  });

  if(!this._heartbeatActive){
    const heartbeatFrequency = parseInt(`${process.env.REACT_APP_WEBSOCKET_HEARTBEAT}` , 10);
    this._heartbeatInterval = setInterval(()=> {
      if(LiveDataServiceClient._webSocket?.readyState === 1){ // OPEN
        LiveDataServiceClient._webSocket.send(JSON.stringify({type: "heartbeat", payload: ""}));
      }
    }, heartbeatFrequency);
    this._heartbeatActive = true;
  }
}
  websocketRetry = () => {
    LiveDataServiceClient._webSocket?.close();
    LiveDataServiceClient._webSocket = undefined;
    document.querySelector('body')?.classList.remove('live-data');
    this.websocketRetryCount++;
    if(this.websocketRetryCount < this.maxWebsocketRetry && !!this._locationId && !!this._dispatch){
      this.subscribe(this._locationId, this._dispatch, this.querySegments );
    } else {
      console.debug('Max retries for websocket live data reached.');
    }
  }

  subscribe = async (locationId: string, dispatch: Function, querySegments: any) => {

    if (!!this._subscriptionHash && this._subscriptionHash !== Object.keys(querySegments).sort().join(',')){
      console.debug('killing previous websocket and old subscription.');
      LiveDataServiceClient._webSocket?.close();
      LiveDataServiceClient._webSocket = undefined;
      document.querySelector('body')?.classList.remove('live-data');
    }
    this._dispatch = dispatch;
    this._locationId = locationId;
    this.querySegments = querySegments;
    this._subscriptionHash =  Object.keys(querySegments).sort().join(',');
    if(!LiveDataServiceClient._webSocket?.readyState){
      document.querySelector('body')?.classList.add('live-data-loading');
      await this.connect(()=>{
        const payload = this.subscriptionMessageGenerator(locationId , 'location', querySegments);
        console.debug(`WS sending subscription message `, payload);
        LiveDataServiceClient._webSocket?.send(JSON.stringify(payload));
      });
    }
  }

  messageHandler = (payload:any) => {
    if(payload) {
      try {
        // compare hash of incoming data keys to active subscription
        const __payload = JSON.parse(payload);
        let hash;
        try{
          hash =  Object.keys(__payload.data).sort().join(',');
        }catch(e){
          console.debug('websocket message:', __payload);
        }
        if(this._dispatch && this._subscriptionHash === hash) {
          this._dispatch(__payload);
        } else {
          console.debug('incoming packet for not active subscription');
        }
    } catch(e) {
        console.error('Error parsing websocket payload', e);
      }
    }
  };

  monitorLiveDataFeed = () => {
    const current = new Date();
    if(!this.fallbackActive && current.valueOf() - (this.lastLiveDataReceived?.valueOf() || (this.liveDataMonitorIntervalPeriod + 1000))> this.liveDataMaxLag){
      console.debug('live data monitor exceeded max lag. starting fallback polling.');
      this.fallbackActive = true;
      this.fallback();
    } else if(this.fallbackActive && current.valueOf() - (this.lastLiveDataReceived?.valueOf() || (this.liveDataMonitorIntervalPeriod + 1000))< this.liveDataMaxLag){
      this.endFallback();
    }
  }

  fallback = async () =>{
    document.querySelector('body')?.classList.remove('live-data');
    document.querySelector('body')?.classList.add('live-data-fallback');
    const current = new Date();
    const dateRange = {
      start: new Date(current.valueOf() - (1000 * 60 * 10)), // fetch the latest 10 minute samples, use the most recent.
      end: current,
      interval: '1m'
    }
    const hdsResponse = await this.publicServiceClient.getHistoricalDataByQuerySegmentsForChart(
      this._locationId,
      dateRange.start,
      dateRange.end,
      this.querySegments,
      dateRange.interval ,
      undefined);
    const lastMinuteData :any = {
      data:{}
    }
    try{
    Object.keys(hdsResponse[0].series).forEach((qs)=>{
      lastMinuteData.data[qs as keyof Object] = {p: hdsResponse[0].series[qs as keyof Object].p.pop()}
    });
  } catch(e){
    console.error('sketchy hds response', hdsResponse);
  }
    this.messageHandler(JSON.stringify(lastMinuteData));
    document.querySelector('body')?.classList.remove('live-data-loading');
    this.minuteDataTimeoutId = setTimeout(this.fallback, 60000);
  }

  endFallback = () => {
    console.debug('end of live data fallback');
    document.querySelector('body')?.classList.remove('live-data-fallback');
    clearTimeout(this.minuteDataTimeoutId);
    this.fallbackActive = false;
  }

  constructor(token:string, publicServiceClient :any) {
    if(!LiveDataServiceClient.instance) {
      this.publicServiceClient = publicServiceClient;
      this._token = token;
      this._currentWebSocketStatus = _WebSocketManagerStates.UNCONNECTED;
      LiveDataServiceClient.instance = this;
    }
    return LiveDataServiceClient.instance;
  }
}
export default LiveDataServiceClient;
