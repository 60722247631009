import * as d3 from 'd3';
import moment from 'moment-timezone';
import Joi from "joi";
import { formatterDate, formatterTime, formatterShortWeekDay, getTimezoneOffset, setUpBins,  } from './timeFunctions';

export type StackedAreaChartOptions = {
  [key:string]: any;
  data: {
    start: string;
    interval_seconds: number;
    measure: string;
    series: {
      [key:string]: {
        [key:string]: number[]
      }
    }
  }[]
};

type GridItem = {
  date: Date;
  [key:string]: number | Date | null;
};

class StackedAreaChart {
  name = 'StackedAreaChart';
  formatterNumber = new Intl.NumberFormat(undefined);
  palette = ['#00abd3','#fff18d','#f89f50','#c3f294','#d15056','#6b427c','#b5b5b5','#aef9ff','#d5c865','#df8b3d','#7cc368','#ffbbd4','#cc83dd','#888888','#dbdbdb','#6bad59','#eadc79','#2bdefc','#e9868e','#ffd7fc','#ffcea9'];
  dateDomain :string[] = [];
  xScale : any;
  yScale : any;
  colorDomain :any  = [];
  color : any;
  svg :any;
  options: StackedAreaChartOptions;
  yAxis :any;
  stackedData :any;
  querySegmentIds : string[];
  inactiveSeries :any;
  startOfSeries :Date|undefined;
  dataMap: Map<number, { [key:string]: number | null }>;
  grid: GridItem[];
  aggregatedSeries : any;
  xBins : Date[];
  bisect : any;

  constructor (options: StackedAreaChartOptions) {
    console.debug('Init stacked area chart.\n', options);
    this.querySegmentIds = [];
    this.xBins = [];
    this.options = options;
    this.dataMap = new Map();
    this.grid = [];
    if(!options.data?.length){
        return;
    }
    this.options = options;
    this.inactiveSeries = new Set();
    this.xBins = [];
    this.querySegmentIds = Array.from(
      options.data.reduce(
        (acc:Set<string>, {series}:any) => {
          for(const querySegmentId of Object.keys(series)){
            acc.add(querySegmentId);
          }
          return acc;
        },
        new Set()
      )
    );
    this.startOfSeries = new Date(options.data[0].start);
    this.prepData();
    this.prepChartDeps();
    this.main();
};
async main () {
    this.updateStacks();
    this.createAxes();
};
resize (newSize : any) {
  this.options.chartConstants.width = newSize.width;
  this.options.chartConstants.height = newSize.height;
  this.svg = d3.select("#historicalChart").select('svg');
  this.svg?.append("g").attr('class', 'stacks');
  this.createScales();
  this.main();
};
prepData () {
  // Make sure there are no negative power values
  for(const result of this.options.data){
    for(const seriesKey of Object.keys(result.series)){
      result.series[seriesKey][this.options.measure] = result.series[seriesKey][this.options.measure].map(v => Math.max(0, v));
    }
  }

  // parse all results and create a flat map of dates and values
  for(const { start, interval_seconds, series } of this.options.data){
    const seriesLength = series[Object.keys(series)[0]][this.options.measure].length;
    const startDate = new Date(start);
    for(let i=0; i<seriesLength; i+=1){
      let d = new Date(startDate.getTime() + interval_seconds * i * 1000);
      let item = this.dataMap.get(d.getTime());
      if(!item){
        item = {}; 
        this.dataMap.set(d.getTime(), item);
      }
      for(const [querySegmentId, measures] of Object.entries(series)){
        item[querySegmentId] = (measures[this.options.measure] || [])[i] ?? null;
      }
    }
  }

  this.dateDomain.push(...Object.keys(this.dataMap).map(time => new Date(time).toISOString()));
  this.xBins = Array.from(this.dataMap.keys()).map(time => new Date(time));
  this.aggregateSeries();
};
aggregateSeries () {
  const totalSamples = this.xBins.length;
  this.aggregatedSeries = new Array(totalSamples).fill(0);
  this.grid = [];
  
  for(const [i, [time, values]] of Array.from(this.dataMap.entries()).entries()){
    const item : GridItem = this.grid[i] = { date: new Date(time) };
    for(const [querySegmentId, value] of Object.entries(values)){
      item[querySegmentId] = (value || 0)  * (this.inactiveSeries?.has(querySegmentId)? 0 : 1);
      if(!isNaN(value as number)){
        this.aggregatedSeries[i] += (value || 0)  * (this.inactiveSeries?.has(querySegmentId)? 0 : 1);
      }
    }
  }
};
createScales () {
  this.xScale = d3.scaleUtc()
  // .domain(this.dateDomain)
  .domain([this.grid[0].date, this.grid[this.grid.length-1].date])
  .range([0, this.options.chartConstants.width]);
  // .paddingInner(0.08);

this.yScale = d3.scaleLinear()
  .domain([0, Math.max(...this.aggregatedSeries)])
  .range([ this.options.chartConstants.height, 0 ]);

};
prepChartDeps () {
  this.createScales();
  this.stackedData = d3.stack()
  .keys(this.querySegmentIds)
  .order((series: any) => {
    let n = series.length;
    const sum : {[key: string]: number;} = {};
    for (let i = 0; i < n; i++){
      sum[i.toString()] = series[i].reduce(
        (accumulator : any , currentValue : any ) => {
          const area = Math.abs(currentValue[1] - currentValue[0]);
          return accumulator + area;
        },0)
    }
    const rtn = Object.entries(sum).sort((a,b) => b[1]-a[1]).map((series : any) => parseInt(series[0], 10));
    this.colorDomain = rtn.map( ordinal => this.querySegmentIds[ordinal]);
    return rtn.reverse();
  })
  (this.grid as any[]);

  this.color = d3.scaleOrdinal()
    .domain(this.colorDomain)
    .range(this.palette);

  this.bisect = d3.bisector((d: any) => { return d; }).left;
  this.options.makeGraphLegend(this.options.querySegmentLookup, this.color);
  //SVG
  this.svg = d3.select("#historicalChart").select('svg'); 
  this.svg?.append("g").attr('class', 'stacks');
};
  toggleSeries (seriesId: string) {
    if(seriesId === 'all') {
      this.inactiveSeries.clear();
    } else if (seriesId === 'none') {
        this.querySegmentIds.forEach((qsid: any)=>{
            this.inactiveSeries.add(qsid);
        });
    } else if(this.inactiveSeries.has(seriesId)){
        this.inactiveSeries.delete(seriesId);
    } else {
        this.inactiveSeries.add(seriesId);
    }
    this.aggregateSeries();
    const yMax = d3.max(this.aggregatedSeries);
    this.yScale.domain([0, yMax]);
    this.updateAxis();
  
    this.updateStacks();
  };
  createAxes ()  {
    const  _yAxis = d3.axisLeft(this.yScale)
        .tickSizeInner(-1 * this.options.chartConstants.width);
    this.yAxis = this.svg?.append("g")
        .attr('class', 'y-axis')
        .call(_yAxis.tickFormat((d:any)=>{
            return this.formatterNumber.format(d);
        }));
    this.svg.select('.y-axis .tick').remove();

    // now add axis ticks to overlay data
    let selectedTicks :any = [];
    let xTicks:any;
    const timezoneOffset = moment.tz(this.options.location.timezone).utcOffset() / 60;
    switch(this.options.dateRange.range){
      case '3hr':
        selectedTicks = d3.utcMinute.every(15);
        xTicks = d3.axisBottom(this.xScale).ticks(selectedTicks);
        break;
      case 'day':
        selectedTicks = d3.utcHour.every(3);
        xTicks = d3.axisBottom(this.xScale).ticks(selectedTicks);
        break;
      case 'week':
        selectedTicks = d3.utcHour.range(this.grid[0].date,this.grid[this.grid.length-1].date).filter((d) => {
          const rtn = d.getUTCHours()===(-1 * timezoneOffset);
          return rtn;
        });
        xTicks = d3.axisBottom(this.xScale).tickValues(selectedTicks);
        break;
      case 'month':
        selectedTicks = d3.utcHour.range(this.grid[0].date,this.grid[this.grid.length-1].date).filter((d) => {
          const rtn = (d.getUTCHours()===(-1 * timezoneOffset)) && d.getUTCDay()===0;
          return rtn;
        });
        xTicks = d3.axisBottom(this.xScale).tickValues(selectedTicks);
        break;
      case 'year':
        selectedTicks = d3.utcMonth.every(1);
        xTicks = d3.axisBottom(this.xScale).ticks(selectedTicks);
        break;
      };

    this.svg.append("g")
      .attr('class', 'x-axis')
      .attr("transform", `translate(0, ${this.options.chartConstants.height})`)
      .call(xTicks.tickFormat((d:any)=>{
          let formatPattern = 'hh:mma';
          switch (this.options.dateRange.range){
            case 'week':
            case 'month':
            case 'year':
              formatPattern = 'MMMM Do';
          }
          const label = moment(d).tz(this.options.location.timezone).format(formatPattern);
          return label;
        })
    )
      .call((g : any) => g.selectAll("line")
        .attr("stroke", "#666")
        .attr("stroke-opacity", 0.4))


    if(window.innerWidth < 500){
      this.svg.select('.x-axis')
          .selectAll("text")
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", function () {
              return "rotate(-60)";
          });
  }


  //create rect 
  this.svg
  .append('rect')
  .style("fill", "none")
  .style("pointer-events", "all")
  .attr('width', this.options.chartConstants.width)
  .attr('height', this.options.chartConstants.height)
  .on('mousemove', this.mousemove.bind(this));


  };
  updateAxis () {
    const  _yAxis = d3.axisLeft(this.yScale);
    this.yAxis.transition().duration(250).call(_yAxis.tickFormat((d:any)=>{
        return this.formatterNumber.format(d/1000);
    }));
    this.svg.select('.y-axis .tick').remove();
    this.svg.selectAll('.y-axis line').transition()
        .attr('x2', this.options.chartConstants.width);
  };
  updateStacks () {
    const stackData = d3.stack()
      .keys(this.querySegmentIds)
      .order((series: any) => {
        let n = series.length;
        const sum : {[key: string]: number;} = {};
        for (let i = 0; i < n; i++){
            sum[i.toString()] = series[i].reduce(
            (accumulator : any , currentValue : any ) => {
                const area = Math.abs(currentValue[1] - currentValue[0]);
                return accumulator + area;
            },0)
        }
        const rtn = Object.entries(sum).sort((a,b) => b[1]-a[1]).map((series : any) => parseInt(series[0], 10));
        return rtn.reverse();
        })
        (this.grid as any[]);

    //TODO needed? 
    stackData.forEach((stackedBar) => {
          stackedBar.forEach((stack :any, i :any) => {
            stack.id = `${stackedBar.key}`;
          });
        });


    // console.log('this is the stacked data' , stackData);
    this.stackedData = stackData;
    const bands = this.svg
      .selectAll('.stacks')
      .selectAll('.qseg')
      .data(this.stackedData, (d:any) => { return d.key});
    bands.join(
        (enter : any) => {
          return enter.append("path")
          .attr('class' , (d: any)=>{return `qseg series-${d.key}`;})
          .style("fill", (d:any)=>{ 
            const fillColor: any = this.color(d.key);
            return fillColor;
          })
          .attr("d", d3.area()
            .x((d: any)=>{ 
              return this.xScale(d.data.date); })
            .y0((d: any)=>{ 
              return this.yScale(d[0]); })
            .y1((d: any)=>{ 
              return this.yScale(d[1]); })
          );
        },
        (update : any) => {
          return update
            .transition()
            .ease(d3.easeCubicOut)
            .duration(250)
            .attr("d", d3.area()
            .x((d: any)=>{ 
              return this.xScale(d.data.date); })
            .y0((d: any)=>{ 
              return this.yScale(d[0]); })
            .y1((d: any)=>{ 
              return this.yScale(d[1]); })
          );
        },
        (exit :any) => {
            return exit.remove();
        }
      );
  };
  findSeries (xVal: any, yVal: any, y: any, colorDomain: any, gridSample: any) {
    if(!gridSample) {
      return undefined;
    }
    const target = y.invert(yVal);
    let sum = 0;
    let i = 0;
    const seriesOrder = colorDomain.toReversed();
    // console.log('Finding series at this value : ',  y.invert(yVal));
    while (sum < target) {
      // console.log('Add next series', gridSample[seriesOrder[i]], sum, target, sum < target);
      sum = sum + gridSample[seriesOrder[i]];
      // console.log('loop again?',  sum, y.invert(yVal), (sum < y.invert(yVal)), i);
      i = i + 1;
    }
    // console.log(y(sum), y.invert(yVal), i);
    return seriesOrder[i - 1];
  };
  mousemove(event: any) {

    const dateAtFocus = this.xScale.invert(d3.pointer(event)[0]);
    const index = this.bisect(this.xBins, dateAtFocus);
    const sampleDate = this.grid[index]?.date;
    const sampleEndDate = this.grid[index + 1]?.date;
    if(!sampleDate){
      d3.select('.chartToolTip').style('display', 'none');
      return;
    }

    const cursorDate = moment(sampleDate).tz(this.options.location.timezone);

    const rightEdgeFlag = (event.pageX > (window.innerWidth - 140)) ? -120 :0;
    const bottomEdgeFlag = (event.pageY > (window.innerHeight - 140)) ? -140 :0;

    d3.select('.chartToolTip')
      .style('top', `${event.pageY + bottomEdgeFlag}px`)
      .style('left', `${event.pageX + rightEdgeFlag}px`);

    d3.select('.ttDate')
      .text(cursorDate.format('ddd M/D/YY'));

    d3.select('.ttTime')
      .text(`${cursorDate.format('h:mm A')} - ${moment(sampleEndDate).tz(this.options.location.timezone).format('h:mm A')}`);

    const highlightedSeries = this.findSeries (d3.pointer(event)[0],d3.pointer(event)[1], this.yScale, this.colorDomain, this.grid[index]);

    if(!highlightedSeries){
      d3.select('.chartToolTip').style('display', 'none');
    } else {
      d3.select('.chartToolTip').style('display', 'block');
      const seriesColor : any = this.color(highlightedSeries);
      d3.select('.chartTTSeries')
        .text(this.options.querySegmentLookup[highlightedSeries])
        .style('background', seriesColor);
      
      const totalConsumption = this.aggregatedSeries[index];
      const seriesConsumption =  Math.floor(this.grid[index][highlightedSeries] as number);
      const percentConsumption =  Math.floor(100 * seriesConsumption / totalConsumption);

      
      d3.select('.ttSeriesValue').text(`${this.formatterNumber.format(seriesConsumption)} `);
      d3.select('.ttMeasure').text(`${this.options.measure === 'p' ? 'watts' : 'kWh' }`);
      d3.select('.ttpercentTotal').text(`${percentConsumption}% `);
      d3.select('.ttAllCircuitsValue').text(`${this.formatterNumber.format(Math.floor(totalConsumption))} `);
      d3.select('.ttAllCircuitsMeasure').text(`${this.options.measure === 'p' ? 'watts' : 'kWh' }`);
    }  
    if(this.grid[index]){ //might be missing data for a given time.
      const gridSample = this.grid[index];
      Object.keys(gridSample).forEach( (key: string) => {
        if(key === 'date' && gridSample[key] instanceof Date){
          const d = gridSample[key].getDate() + ' ' + gridSample[key].toLocaleString('default', { month: 'short' }) + ' ' 
          + gridSample[key].getFullYear() + ' ' + String(gridSample[key].getHours()).padStart(2,'0') + ':' + String(gridSample[key].getMinutes()).padStart(2, '0');
          d3.select(`span.series-${key}`).text(d);
        } else {
          d3.select(`span.series-${key}`).text(Math.floor(gridSample[key] as number));
        }

        const stack = this.stackedData.find((s : any) => {
          return s.key === key;
        });
        if(stack){
          const avgY = this.yScale(stack[index][1]);
          const stackColor : any = this.color(key);
          d3.select(`#cursor-${key}`)
            .attr("fill", stackColor)
            .attr('cx', this.xScale(dateAtFocus))
            .attr('cy', avgY);
        }
      });
    }
 };

};
export {
  StackedAreaChart
};