import React, { useState, useEffect , useCallback, useRef} from 'react';
import { FiMenu } from "react-icons/fi";
import * as d3 from 'd3';
import LoadingWidget from '../../components/LoadingWidget';
import ErrorWidget from '../../components/ErrorWidget';
import DateRangePicker from '../DateRangePicker';
import PublicService from '../../services/Public';
import MeasurePicker from '../MeasurePicker';
import { retrieveData } from './data';
import { NetPowerChart } from './chartNetPower';
import { renderNetEnergyChart } from './chartNetEnergy';
import { BarChart } from './chartConsumptionEnergy';
import { StackedAreaChart, StackedAreaChartOptions } from './chartConsumptionPower';
import LiveConsumption from '../../components/LiveConsumption';
import moment from 'moment-timezone';
import Insights from '../Insights';

interface range {
  start: Date;
  end?: Date;
  range?: string;
  interval?: string;
};
const chartHistory: any = [];
let loc:any;
let chart :any;

const History = ({location, publicService, token, liveData, querySegmentLookup}:{location: any, publicService:PublicService, token: string, liveData: any, querySegmentLookup : any}) => {
  const chartData = useRef<any>();
  const [endDate, setEndDate] = useState(new Date());
  const [errorVanish, setErrorVanish] = useState(true);
  const [erroring, setErroring] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [lastLocation, setLastLocation] = useState<string | null>(null);
  const [lastMeasure, setLastMeasure]  = useState('p');
  const [lastRange, setLastRange] = useState<string | undefined>('week');
  const tabRef = useRef('consumption');
  const [lastTab, updateLastTab] = useState('consumption');
  const setLastTab = (tab: string) => {
    tabRef.current = tab;
    updateLastTab(tab);

  }
  const [liveConsumption, setLiveConsumption] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingVanish, setloadingVanish] = useState(false);
  const [chartLegendOpen, setChartLegendOpen] = useState(false);
  const [measure, setMeasure]  = useState('e');
  const [graphSeriesKeys, setGraphSeriesKeys] =   useState<{[key: string]: string;}>({});
  const [colorMap, setColorMap] = useState(new Map());
  const [dateRange, setDateRange] =  useState({start: new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000), end: endDate,  range: 'week', interval : '1d'} as range);
  const [tab, setTab] = useState('consumption');
  let controller: any = useRef(undefined);
  let inflight: any = useRef(false);
  const getChartOptions = () => {
  return {
    data : undefined,
    location: loc,
    querySegmentLookup: undefined,
    chartConstants : {
      width : window.innerWidth,
      height: window.innerHeight - (document.querySelector('#historicalChart')?.getBoundingClientRect()?.top || 218) - 80,
      margin: {top: 0, right: 0, bottom: 80, left: 0}
    },
    measure,
    dateRange,
    setLoading,
    showError,
    makeGraphLegend
  };
};
  const chartSlug = (options: any) => {
    return `${options?.location?.uuid}-${options?.measure}-${options?.dateRange?.range}-${options?.dateRange?.start?.valueOf()}`;
  }
  const makeChart = (obj:any) => {
    if(!obj){ return null;}
    let data = [];

    if(obj && obj.hasOwnProperty('data')){
      data = obj.data;
    }
    const qsLookup = obj.querySegmentLookup;
    if (!data.find((result:any) => !!result.series)) {
      console.warn('No data for this location.');
      showError(new Error('No data for this location.'));
    }
    const chartOptions : any = getChartOptions();
    chartOptions.measure = obj.chartMeasure || chartOptions.measure;
    chartOptions.dateRange.range = obj.chartRange || chartOptions.dateRange.range;
    document.querySelector('#historicalChart')?.replaceChildren();
     const svg:any  = d3.select("#historicalChart")
      .append("svg")
        .attr('id', 'chart')
        .attr("width", chartOptions.chartConstants.width + chartOptions.chartConstants.margin.left + chartOptions.chartConstants.margin.right)
        .attr("height", chartOptions.chartConstants.height + chartOptions.chartConstants.margin.top + chartOptions.chartConstants.margin.bottom);
    const svgChart :any = document.querySelector('svg#chart');
    const newSize = {
      height: svgChart?.height.baseVal.value - 80,
      width: svgChart?.width.baseVal.value
    };
    console.debug(`svg dimensions`, newSize.width, newSize.height);
      chartOptions.querySegmentLookup = qsLookup;
      chartOptions.data = data as StackedAreaChartOptions[];
      const chartKey = (obj.chartTab && obj.chartMeasure)? `${obj.chartTab}-${obj.chartMeasure}` : `${tab}-${measure}`;
      switch ( chartKey ) {
        case 'consumption-p':
          if(!!chart && chart.name === 'StackedAreaChart' && (chartSlug(chart.options) === chartSlug(chartOptions)) ){
            chart.resize(newSize);
          } else {
            chart = new StackedAreaChart(chartOptions);
          }
          break;
        case 'consumption-e':
          if(!!chart && chart.name === 'BarChart' && (chartSlug(chart.options) === chartSlug(chartOptions)) ){
            chart.resize(newSize);
          } else {
            chart = new BarChart(chartOptions);
          }
          break;
        case 'solar-p':
          if(!!chart && chart.name === 'NetPowerChart' && (chartSlug(chart.options) === chartSlug(chartOptions)) ){
            chart.resize(newSize);
          } else {
            chart = new NetPowerChart(chartOptions);
          }
          break;
        case 'solar-e':
          renderNetEnergyChart(chartOptions);
          break;
      }
      setloadingVanish(true);
      setTimeout(() => {
        setLoading(false);
      },1000);
      d3.select("#historicalChart").classed("vanish", false);

  };

const clear = () => {
  d3.select("#historicalChart").classed("vanish", true);
  d3.select("#historicalChart").selectAll("*").remove();
  d3.select('.tooltip-solar').style('display', 'none');
  setGraphSeriesKeys({});
  setChartLegendOpen(false);
  setErroring(false);
  setErrorVanish(true);
  setErrorMessage('');
  setloadingVanish(false);
  setLoading(true);
};
const showError = (err: Error) => {
  setloadingVanish(true);
  setErrorMessage(err.message);
  setErrorVanish(false);
  setErroring(true);
  console.error(err);
};
const pickTab = (incomingTab: string) => {
  if(incomingTab === 'insights'){
    setloadingVanish(false);
    setLoading(true);
    clear();
    setLiveConsumption(false);
    d3.select('.date-align').style('display', 'none');
    d3.select('.measure-align').style('display', 'none');
    main();
    // setTimeout(() => {
    //   setLoading(false);
    // },1000);
  } else if(incomingTab === 'live'){
    clear();
    abort();
    d3.select('.date-align').style('display', 'none');
    d3.select('.measure-align').style('display', 'none');
    setLiveConsumption(true);
    setloadingVanish(true);
    setTimeout(() => {
      setLoading(false);
    },1000);
  } else {
    //TODO 🤔 close websocket?
    setLiveConsumption(false);
    d3.select('.date-align').style('display', 'block');
    d3.select('.measure-align').style('display', 'block');
    setChartDateRange(dateRange, measure, incomingTab);
  }
  setTab(incomingTab);
};
const setChartMeasure = (incomingMeasure: string)=>{
  setChartDateRange(dateRange, incomingMeasure, tab);
  setMeasure(incomingMeasure);
};
const setChartDateRange = (incomingRange: any, incomingMeasure: any, incomingTab: any) => {
  incomingRange.end = incomingRange.end || new Date();
  let interval;
  const chartOptions = `${incomingTab || tab}-${incomingMeasure || measure}-${incomingRange.range || dateRange.range}`;
  switch (chartOptions){
    case 'consumption-e-3hr':
       interval = '1h';
       break;
    case 'consumption-e-day':
        interval = '1h';
        break;
    case 'consumption-e-week':
        interval = '1d';
        break;
    case 'consumption-e-month':
        interval = '7d';
        break;
    case 'consumption-e-year':
        interval = '30d';
        break;
    case 'consumption-p-day':
        interval = '1m';
        break;
    case 'consumption-p-3hr':
       interval = '1m';
       break;
      case 'consumption-p-week':
          interval = '1h';
          break;
    case 'consumption-p-month':
          interval = '1h';
          break;
    case 'consumption-p-year':
      interval = '1d';
      break;
    case 'solar-e-3hr':
      interval = '15m';
      break;
    case 'solar-e-day':
      interval = '1h';
      break;
    case 'solar-e-week':
      interval = '1d';
      break;
    case 'solar-e-month':
      interval = '7d';
      break;
    case 'solar-e-year':
      interval = '30d';
      break;
    case 'solar-p-day':
      interval = '1m';
      break;
    case 'solar-p-3hr':
      interval = '1m';
      break;
    case 'solar-p-year':
        interval = '1d';
        break;
    default:
    interval = '1h';
    }
  const newRange: range = {start: incomingRange.start, end: incomingRange.end, interval, range: incomingRange.range};
  console.debug(`Setting range to `, newRange);
  setDateRange(newRange);
}

const makeGraphLegend = (qsl:any, color:any) => {
  setGraphSeriesKeys(qsl);
  // React won't set this function as a value...🤷, converting to a dictionary object.
  const colorMap = new Map();
  Object.keys(qsl).forEach((series) => {
    colorMap.set(series, color(series));
  });
  setColorMap(colorMap);
};
const graphLegend = (qsl: any, cL:any = null) => {
  if(!(Object.keys(qsl).length > 0) || !(cL?.size > 0)){
    return (<div></div>);
  }
  return (
  <div className="chartLegendWrapper">
    <div className={"chartLegendHandle"} onClick={() => { toggleChartLegend() }}>{chartLegendOpen ? '▲' : '▼'}<FiMenu /></div>
    <div className={`chartLegend ${chartLegendOpen? 'open' : 'closed' }`}>
      <div className='chartLegend-deselect-all' onClick={(e) => {toggleSeries(e, 'none')}}>Deselect all</div>
      <div className='chartLegend-select-all' onClick={(e) => {toggleSeries(e, 'all')}}>Select all</div>
      <ul className='qs-list'>
        {Object.keys(qsl)
        .sort((keya, keyb)=>{
          return (qsl[keya as keyof object] || '').toLocaleLowerCase().localeCompare((qsl[keyb as keyof object] || '').toLocaleLowerCase());})
        .map((key : string) =>
          <li key={key} onClick={(e) => {toggleSeries(e, key)}}>
            <div className='qs-bullet' style={{ backgroundColor: cL.get(key), borderColor: cL.get(key)}}></div>
            <span> {qsl[key as keyof object]}</span>
            <span className={`devmode series-${key}`}></span>
          </li>
        )}
      </ul>
    </div>
  </div>
  );
};
const toggleChartLegend = () => {
  setChartLegendOpen(!chartLegendOpen);
};
const toggleSeries = (event: any, series: string, ) => {
  const lis = document.querySelectorAll('.qs-list li');
  switch (series){
    case 'all':
      lis.forEach((li) => {li.classList.remove('qs-off');});
      break;
    case 'none':
      lis.forEach((li) => {li.classList.add('qs-off');});
      break;
    default:
      const targetLi = (event.target.nodeName == 'LI') ? event.target : event.target.parentNode;
      targetLi.classList.toggle('qs-off');
  }

  if (chart && !!chart.toggleSeries){
    chart.toggleSeries(series);
  }
};

const abort = () => {
  if(controller && controller.current?.abort){
    controller.current.abort();
  }
};
const main = async() => {
  abort();
  controller.current = new AbortController();
  try{
    if(tab==='insights'){
      try {
        setLoading(true);
        const data = await publicService.getInsights(location.uuid);
        if(data?.data && data.data.length > 0){
          chartData.current = data
          setLoading(false);
        } else {
          throw new Error ('No insights data');
        }
      } catch(e:any){
        setLoading(false);
        console.log(e && e.message ? e.message :"error fetching insights");
        chartData.current = {error:true, message: "No information available at this time."};
      }
    } else {
      const showErrorFront = (err: Error) => {
        if(tabRef.current === "insights" &&(lastTab!=="insights")) { return;}
        else {
          showError(err);
        }
      }
      inflight = true;
      const data :any= await retrieveData({locationId: loc.uuid, publicService, tab, measure, dateRange, showError:showErrorFront, signal: controller.current.signal});
      inflight = false;
      if(data){
        data.chartTab = tab
        data.chartMeasure = measure;
        data.chartRange = dateRange.range;
        chartData.current = data
        makeChart(data);
      }
    }
  }catch(e){
    console.error('Is this a fetch abort error?', e);
  }
};
const shouldChangeGraph = () => {
  const rtn = lastTab === 'live' || loc?.uuid?.length >1 && tab !== 'live' && chartHistory[0] !== getChartHash();
  if(tab === 'live'){
    console.debug(`Should change graph?`, loc, tab, chartHistory, getChartHash());
    setLastTab(tab);
  }
  return rtn;
}

const getChartHash = () => {
  return `${loc.uuid}_${tab}_${measure}_${dateRange.range}_${dateRange.start.valueOf()}_${dateRange.end?.valueOf()}`;
}
const reverseChartHash = (chartHash: string) => {
  const [locationId, tab, measure, range, start, end] = chartHash.split('_');
  return {
    locationId,
    tab,
    measure,
    range,
    start : new Date( parseInt(start, 10)),
    end: new Date( parseInt(end, 10))
  }
}

let developerMode = false;
const _pressed :any = [];
const handleKeyPress = useCallback((event : KeyboardEvent) => {
  const length = _pressed.push(event.key);
  if (length > 3){
    _pressed.shift();
  }
  switch(_pressed.join('')){
    case '007':
      developerMode = !developerMode;
      console.log(`toggle developer mode ${developerMode}`);
      document.body.classList.toggle('devmode-on');
    break;
    case 'bbb':
      previousRange();
      break;
    default:
      break;
  }
}, []);
const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
const handleWindowSize = useCallback((event : any) => {
  if(['solar', 'consumption'].includes(tabRef.current) && !(inflight.current)){
    makeChart(chartData.current);
  }
}, []);

const previousRange = () => {
  console.debug('back...',chartHistory,  getChartHash(), reverseChartHash(getChartHash()));
  const currentOptions = reverseChartHash(getChartHash());
    currentOptions.end = currentOptions.start;
  switch(currentOptions.range) {
    case '3hr':
      currentOptions.start = moment(currentOptions.start).subtract(3, 'hours').toDate();
      break;
    case 'day':
      currentOptions.start = moment(currentOptions.start).subtract(1, 'days').toDate();
      break;
    case 'week':
      currentOptions.start = moment(currentOptions.start).subtract(1, 'weeks').toDate();
      break;
    case 'month':
      currentOptions.start = moment(currentOptions.start).subtract(1, 'months').toDate();
      break;
    case 'year':
      currentOptions.start = moment(currentOptions.start).subtract(1, 'years').toDate();
      break;
    }
    console.debug(currentOptions);
    const newRange: range = {start: currentOptions.start, end: currentOptions.end, interval: dateRange.interval, range: currentOptions.range};

    console.log(this);
    setDateRange(newRange);
    clear();
    main();
   setLastLocation(loc.uuid);
   setLastMeasure(measure);
   setLastRange(dateRange.range);
   setLastTab(tab);
   chartHistory.unshift(getChartHash())
}



  useEffect(() => {
    const deboucedHandleWindowSize = debounce(handleWindowSize);
    document.addEventListener('keydown', handleKeyPress);
    window.addEventListener("resize", deboucedHandleWindowSize);
    loc = location;
    if(shouldChangeGraph()){
      console.info(`The current selected options: ${loc.uuid}-${measure}-${dateRange.range}-${tab} do not match the last options selected ${lastLocation}-${lastMeasure}-${lastRange}-${lastTab}. Fetching new data...`);
      clear();
      main();
      if(tab !== 'insights'){ 
        setLastLocation(loc.uuid);
        setLastMeasure(measure);
        setLastRange(dateRange.range);
      }
      setLastTab(tab);
      chartHistory.unshift(getChartHash())
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener("resize", deboucedHandleWindowSize);
    };
}, [location, measure, dateRange, tab]);
return (
    <div id="historicalWrapper">
      <div className="graphInputs">
        <div className='chart-tabs'>
          <span className={`chart-tab ${tab==="consumption"?"highlight":""}`} onClick={() => pickTab('consumption')}>ENERGY USE</span>
          <span className={`chart-tab ${tab==="solar"?"highlight":""}`} onClick={() => pickTab('solar')}>SOLAR/NET</span>
          <span className={`chart-tab ${tab==="live"?"highlight":""}`} onClick={() => pickTab('live')}>LIVE USAGE</span>
          <span className={`chart-tab ${tab==="insights"?"highlight":""}`} onClick={() => pickTab('insights')}>A/C INSIGHTS</span>
        </div>
        <div className="date-align">
          <DateRangePicker setDateRange={setChartDateRange} dateRange={dateRange}></DateRangePicker>
        </div>
        <div className="measure-align ">
          <MeasurePicker setMeasureParent={setChartMeasure}></MeasurePicker>
        </div>
      </div>
      {erroring && <ErrorWidget vanish={errorVanish} message={errorMessage}></ErrorWidget>}
      {loading && <LoadingWidget vanish={loadingVanish} message="Loading data for this location."></LoadingWidget>}
      {liveConsumption && <LiveConsumption liveData={liveData} querySegmentLookup={querySegmentLookup}/>}
      <div className={loading?"hide-this":""} id="historicalChart"></div>
      {tab==="insights" && <Insights data={chartData.current} loading={loading} />}
      {graphLegend(graphSeriesKeys, colorMap)}
      <div className="chartToolTip">
        <div className="chartTTHeader">
          <div className="ttDate"></div>
          <div className="ttTime"></div>
        </div>
        <div className="chartTTBody">
          <div>
          <span className="ttSeriesValue"></span>
          <span className="ttMeasure"></span>
          </div>
          <div>
          <span className="ttpercentTotal"></span>
          <span>of total</span>
          </div>
          <div>
          <span className="ttAllCircuits">ALL CIRCUITS</span>
          <span className="ttAllCircuitsValue"></span>
          <span className="ttAllCircuitsMeasure"></span>
          </div>
        </div>
        <div className="chartTTSeries"></div>
      </div>
      <div className="tooltip-solar">
        <div className="chartTTHeader">
          <span className="ttDate"></span>
          <span className="ttTime"></span>
        </div>
        <div className="tooltip-solar-body">
          <span className="tooltip-solar-measure measure-consumption"></span>
          <span className="tooltip-solar-measure measure-production"></span>
          <span className="tooltip-solar-measure measure-grid_net"></span>
        </div>
        <div className="tooltip-solar-label label-consumption">Consumption</div>
        <div className="tooltip-solar-label label-production">Production</div>
        <div className="tooltip-solar-label label-net">Net</div>
      </div>
      <div className="chartToolTipNetEnergy">
        <div className="chartTTHeader">
          <div className="ttDate"></div>
          <div className="ttTime"></div>
        </div>
        <div className="chartTTBody">
          <div>
          <span className="ttSeriesValue"></span>
          <span className="ttMeasure"></span>
          </div>
        </div>
        <div className="chartTTSeries"></div>
      </div>
    </div>
    );
};
export default History;
