import { useEffect } from 'react';
const formatterNumber = new Intl.NumberFormat(undefined);
const Live = ({liveData}
  :{liveData:any}) => {
  // useEffect(() => {
  // }, [liveData]);
  return (
    <div className={`live-data-widget-container ${(liveData?.data?.production) ? 'has-solar' : ''}`}>
    <div className="live-data-widget-loading">Subscribing to live data</div>
    <div className="live-data-widget" title="Live data">
      <div className="live-data-measure live-data-measure-consumption">
        <div className="live-data-widget-label">POWER USE</div>
        <span className="live-data-widget-value">{formatterNumber.format(Math.round(liveData?.data?.consumption?.p) || 0)}<span>W</span></span>
      </div>
    {liveData?.data?.production &&
      <>
      <div className="live-data-measure live-data-measure-solar">
        <div className="live-data-widget-label">SOLAR</div>
        <span className="live-data-widget-value">{formatterNumber.format(Math.round(liveData?.data?.production?.p))}<span>W</span></span>
      </div>
      <div className="live-data-measure">
        <div className="live-data-widget-label">GRID</div>
        <span className="live-data-widget-value">{formatterNumber.format(Math.round(liveData?.data?.grid_net?.p))}<span>W</span></span>
      </div>
      </>
    }
  </div>
  </div>);
};

export default Live;
