import History from '../../components/History';
import Live from '../../components/Live';
import EULAModal from '../../components/EULAModal';

const Dashboard = ({location, locationId, publicService,  querySegmentLookup, token, liveData}
  :{location:any, locationId:string, publicService:any, querySegmentLookup: any, token: string, liveData: any}) => {
      // console.debug('passed live data' ,liveData);
    return (
    <div className="App flexEnd">
      <div className="row">
      <div className='live-left'>
        {/*
        <span className='monthly-bill-icon'>  </span>
        <span className='monthly-bill'>&nbsp;&nbsp;$94.48</span>
        <span className='monthly-bill-tag'> Bill since July 1st</span>
        */}
      </div>
      <div className='live-right'>
        <Live liveData={liveData} />
      </div>
      </div>
      <div className="row">
        <History location={location} publicService={publicService} token={token} liveData={liveData} querySegmentLookup={querySegmentLookup}/>
      </div>    
      <EULAModal publicService={publicService} />
    </div>
  );
};

export default Dashboard;