import React, {useState} from 'react';


const LoadingWidget = ({message = "Loading", vanish, minheight=""}:{message:string, vanish:boolean, minheight?:string}) => {
  
  let st:any;
  if(minheight) st = {zIndex: 9, minHeight: minheight}
  else st = {zIndex: 9};
  return (
  <div className={`loading-screen ${vanish?'vanish': 'appear'} loading-widget widget-occupier`}  style={st}>
    <p style={{color:"var(--elevation-blue)"}}>{message}</p>
    <div className="loading-screen__spinner">
      <div className="loading-screen__spinner__inner"> 
        <div className="loading-screen__spinner__inner__dot">
          <div className="spinning-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    </div>
  </div>);
};
export default LoadingWidget;
