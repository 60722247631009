import { IAspectValue, ICategory, IRegister } from './Insights';
import Register from './Register';

const Category = ({category}:{category:ICategory}) => {
    if(!category.data) return null;
    return (
        <div key={category.key}>
            {category.data.map((register:IRegister|IAspectValue) => {
                if (typeof register === "object" && register.hasOwnProperty('data')) {
                    return (
                        <Register 
                            // @ts-ignore
                            key={register.key||register.label}
                            // @ts-ignore
                            register={register} />
                    );
                }
                return null;
            })}
        </div>
    );
}

export default Category;
